module.exports = {
  defaultTitle: 'Ansgar Sachs',
  logo: 'https://www.ansgar-sachs.de/favicon/favicon-512.png',
  author: 'Ansgar Sachs',
  url: 'https://www.ansgar-sachs.de',
  legalName: 'Ansgar Sachs',
  defaultDescription: 'I’m Ansgar and I’m a Senior Backend engineer!',
  socialLinks: {
    twitter: 'https://twitter.com/AnsgarSachs',
    github: 'https://github.com/ansgarS',
    linkedin: 'https://www.linkedin.com/in/ansgar-sachs',
    instagram: 'https://www.instagram.com/ansgar_espunkt',
    youtube: 'https://www.youtube.com/user/ansgarsachs',
    google: 'https://plus.google.com/u/0/+ansgar_espunkt',
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'ansgar.sachs',
    twitter: '@AnsgarSachs',
  },
  address: {
    city: 'Koblenz',
    region: 'Rhineland-Palantine',
    country: 'Germany',
    zipCode: '56073',
  },
  contact: {
    email: 'ansgar.sa@gmail.com',
    phone: '+49 1525 6367157',
  },
  foundingDate: '2021',
};
